import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const whiteLabelDomainApi = new ApiCrud(http.AXIOS, {
  create: 'POST whitelabel_domains?acctId=:acctId',
  update: 'PUT whitelabel_domains/:orgId?acctId=:acctId',
  get: 'GET whitelabel_domains/:orgId',
  delete: 'DELETE whitelabel_domains/:orgId',
  getDomainName: 'GET whitelabel_domains/get_by_domain_name?domainName=:id',
});

export default whiteLabelDomainApi;
